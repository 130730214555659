import React, { Fragment } from "react";

import Breadcrumb from '../../layout/breadcrumb'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Container, Row, Col, Card, CardBody, FormGroup } from 'reactstrap'
import { services } from '../../services/services';
import { translate } from 'react-switch-lang';
import SweetAlert from 'sweetalert2'
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import product4 from '../../assets/images/ecommerce/product-table-4.png';

import ImageUploader from 'react-images-upload';
import Dropzone from 'react-dropzone-uploader'
import { numberWithCommas } from '../../helpers/index';
import { img_base_url, img_server } from '../../env';
import Media from 'react-media';

import "antd/dist/antd.css";
import { Upload, Icon, message } from 'antd';




const moment = require('moment')

// createTheme creates a new theme named solarized that overrides the build in dark theme
const { Dragger } = Upload;



class ListeArticles extends React.Component {
    state = {
        activeTab: "1",
        currentPage: 0,
        pageSize: 10, //TAILLE CHARGEMENT PAR DEFAULT
        etatCmde: 'EN COURS',
        dateEnCours: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
        open: false,
        listeArticlesSelected: [],
        nbresArticles: 0,
        commande: '',
        tableauPagination: [],
        commandesPointLenght: 0,
        commandesPointData: [],
        loading: true,
        emptyData: false,
        articles: [],
        totalArticles: 0,
        produitSelectionne: ''

    }


    componentDidMount() {

        services.getArticlesAll()
            .then(articles => {
                //console.log('articles', articles)
                if (articles.length > 0) {
                    this.setState({ articles: articles, loading: false })

                } else {
                    this.setState({ emptyData: true })
                }
            })

        services.getCategories()
            .then(categories => {
                //console.log('categories', categories)
                this.setState({ categories })
            })


    }

    handleModifierProduit = (row) => {
        this.setState({ produitSelectionne: row, open: true, idProd: row.id })
    }

    onCloseModalDetailCommande = () => {
        this.setState({ open: false, produitSelectionne: '', listeArticlesSelected: [] });

    };


    handleChangeSelectCategorie = (selectedOption) => {
        this.setState({
            loading: true,
            emptyData: false,
            articles: [],
        })
        services.getArticlesAll()
            .then(articles => {

                //console.log('articles', articles)
                const dataFilter = articles.filter(x => x.codeCat === selectedOption.value)
                if (dataFilter.length > 0) {
                    this.setState({
                        articles: dataFilter,
                        loading: false
                    })

                } else {
                    this.setState({ emptyData: true })
                }

            })

        this.setState({
            selectedOption: selectedOption,
            codeCat: selectedOption.value,

        });

       // console.log(`Option selected:`, selectedOption);
    };

    //IMAGE
    getUploadParams = ({ file, meta }) => {
        //console.log('fileTest', file)
        const {
            idProd
        } = this.state

        let fileUpload = new FormData()
        fileUpload.append('fileUpload', file);

        this.setState({ loading: true, emptyData: false })

        services.updateArticleImage(idProd, fileUpload)
            .then(result => {
                //console.log(result)
                this.setState({ open: false })
                services.getArticlesAll()
                    .then(articles => {
                       // console.log('articles', articles)
                        if (articles.length > 0) {
                            this.setState({ articles: articles, loading: false, open: false })

                        } else {
                            this.setState({ emptyData: true })
                        }
                    })
                SweetAlert.fire({ title: "Félicitation!", text: "Produit modifier avec succès!", icon: "success" });

            })

            .catch((error) => {
                //console.log('ERROR', error)
                SweetAlert.fire({ title: "Erreur!", text: error, icon: "error" });
                this.setState({ loading: false })
            });

        //body.append('http://localhost:3000/images/produits', file)
        // return {
        //   url: 'http://localhost:3000/images/produits/post', body
        // }
    }

    // receives array of files that are done uploading when submit button is clicked
    handleSubmitFile = (files, allFiles) => {
        //console.log('handleSubmitFile', files.map(f => f.meta))
        allFiles.forEach(f => f.remove())
    }

    // called every time a file's `status` changes
    handleChangeStatus = ({ meta, file }, status) => { console.log('META', meta, file, status) }

    //SECTION MODIFICATION IMAGE POUR STOCKAGE SAILS JS
    onDrop = (pictureFiles, pictureDataURLs) => {
       // console.log('image', pictureFiles)

        const {
            idProd
        } = this.state

        let file = pictureFiles[0]
        let fileUpload = new FormData()
        fileUpload.append('fileUpload', file);

        this.setState({ loading: true, emptyData: false })

        services.updateArticleImage(idProd, fileUpload)
            .then(result => {
                //console.log(result)
                this.setState({ open: false })
                services.getArticlesAll()
                    .then(articles => {
                       // console.log('articles', articles)
                        if (articles.length > 0) {
                            this.setState({ articles: articles, loading: false, open: false })

                        } else {
                            this.setState({ emptyData: true })
                        }
                    })
                SweetAlert.fire({ title: "Félicitation!", text: "Produit modifier avec succès!", icon: "success" });

            })

            .catch((error) => {
               // console.log('ERROR', error)
                SweetAlert.fire({ title: "Erreur!", text: error, icon: "error" });
                this.setState({ loading: false })
            });


    }

    /*handleChangeFile = (selectorFiles) => {
        console.log("fileChange", selectorFiles[0]);
        const {
            idProd
        } = this.state

        let file = new FormData()
        file.append('file', selectorFiles[0]);

        this.setState({loading: true, emptyData: false })
        services.updateArticleImage(idProd, file)
            .then(result => {
                console.log(result)
                services.getArticlesAll()
                    .then(articles => {
                        console.log('articles', articles)
                        if (articles.length > 0) {
                            this.setState({ articles: articles, loading: false })

                        } else {
                            this.setState({ emptyData: true })
                        }
                    })
                SweetAlert.fire({ title: "Félicitation!", text: "Produit modifier avec succès!", icon: "success" });

            })

            .catch((error) => {
                console.log('ERROR', error)
                SweetAlert.fire({ title: "Erreur!", text:error, icon: "error" });
                this.setState({ loading: false })
            });
    }

   /* onDrop = (e, pictureFiles, pictureDataURLs) => {
        console.log('image', e.target.value, pictureFiles)
        this.setState({ image: pictureFiles })

        const fileImage = new FormData()
        fileImage.append("uploadedFiles", e.target.value);
        console.log('fileImage', new FormData().append("uploadedFiles", pictureFiles))

        const {
            idProd
        } = this.state

        let req = {
            idProd,
            file: e.target.value
        }

        services.updateArticleImage(idProd, req)
            .then(result => {
                console.log(result)

                SweetAlert.fire({ title: "Félicitation!", text: "Votre commande a été prise en compte!", icon: "success" });

            })

            .catch((error) => {
                console.log('ERROR', error)
                SweetAlert.fire({ title: "Erreur!", text: error, icon: "error" });
                this.setState({ loading: false })
            });


    }*/
    updateProductImgExpressJs = (info) => {
        //console.log('SUCCESS updateProductImgExpressJs', info)
        let ext = (info.name || "").replace(/.*\.(.+)/, "$1");
        //console.log('EXTENSION', ext, this.state.idProd)
        let req = {
            ext: ext,
        }
        services.updateArticleImageApi(this.state.idProd, req)
            .then(result => {
                //console.log(result)
                this.setState({ open: false })
                services.getArticlesAll()
                    .then(articles => {
                        //console.log('articles', articles)
                        if (articles.length > 0) {
                            this.setState({ articles: articles, loading: false, open: false })

                        } else {
                            this.setState({ emptyData: true })
                        }
                    })
                SweetAlert.fire({ title: "Félicitation!", text: "Produit modifier avec succès!", icon: "success" });

            })

            .catch((error) => {
                //console.log('ERROR', error)
                SweetAlert.fire({ title: "Erreur!", text: error, icon: "error" });
                this.setState({ loading: false })
            });
    }

    render() {

        const {

            articles,
            open,

            selectedOption,
            categories,
            loading,
            emptyData,
            produitSelectionne,

            idProd,
        } = this.state


        const props = {
            name: 'photo',
            accept: "image/*",
            multiple: false,
            action: `${img_base_url}/photo?productId=${idProd}`,
            beforeUpload: this.updateProductImgExpressJs,

            onChange(info) {
               // console.log('IMG', info, `http://localhost:5000/photo?productId=${idProd}`)
                const { status } = info.file;
                if (status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }

                if (status === 'done') {
                    //console.log('-----OK----',)
                    ////////////////////////////
                    //this.updateProductImgExpressJs(info)

                    //////////////////////////
                    //message.success(`${info.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };

        var fd = new FormData();

        // poulate with dummy data
        fd.append("key1", "alskdjflasj");
        fd.append("key2", "alskdjflasj");

        // does not do anything useful
        //console.log("fd", fd);


        const totalArticles = articles.length

        const productColumns = [
            {
                name: "Image",
                selector: "codPro",
                sortable: true,
                center: true,
                format: (row, index) => {
                    return <div>
                        {row.imgLink !== "" ?
                            <img src={`${img_base_url}/images/produits/${row.imgLink}`} style={{ width: 50, height: 50 }} alt="" />
                            : <img src={product4} style={{ width: 50, height: 50 }} alt="" />
                        }

                    </div>

                }

            },
            {
                name: "Code",
                selector: "codPro",
                sortable: true,
                center: true,

            },
            {
                name: "Désignation",
                selector: 'designation',
                sortable: true,
                center: true,
                wrap: true,
                allowOverflow: true,
                omit: false,
                //format: (row, index) => { },
                // cell: (row, index, column, id) => {},
            },
            {
                name: "Catégorie",
                selector: (row) => row && row.categorie ? row.categorie.libCat : '',
                sortable: true,
                center: true,

            },
            {
                name: "Prix Unitaire",
                selector: (row) => parseInt(row && row.prix && row.prix.prixUnitaire),
                sortable: true,
                center: true,
                format: (row, index) => {                                       //loading ? loading : loading
                    return <div className='font-danger' style={{ fontWeight: 'bold' }}>{numberWithCommas(parseInt(row && row.prix && row.prix.prixUnitaire ? row.prix.prixUnitaire : 0))} F Cfa</div>
                }

            },

            {
                name: "Action",
                selector: 'action',
                sortable: true,
                center: true,
                format: (row, index) => {
                    return <div onClick={() => this.handleModifierProduit(row)}>
                        <span style={{ cursor: 'pointer' }}><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i></span>
                    </div>
                },

            },
        ];

        const productColumnsPhone = [
            {
                name: "Image",
                selector: "codPro",
                sortable: true,
                center: true,
                format: (row, index) => {
                    return <div>
                        {row.imgLink !== "" ?
                            <img src={`${img_base_url}/images/produits/${row.imgLink}`} style={{ width: 50, height: 50 }} alt="" />
                            : <img src={product4} style={{ width: 50, height: 50 }} alt="" />
                        }

                    </div>

                }

            },
            /*{
                name: "Code",
                selector: "codPro",
                sortable: true,
                center: true,

            },*/
            {
                name: "Désignation",
                selector: 'designation',
                sortable: true,
                center: true,
                wrap: true,
                allowOverflow: true,
                omit: false,
                //format: (row, index) => { },
                // cell: (row, index, column, id) => {},
            },
            /*{
                name: "Catégorie",
                selector: (row) => row && row.categorie && row.categorie.libCat,
                sortable: true,
                center: true,

            },*/
            {
                name: "Prix Unitaire",
                selector: (row) => parseInt(row.prix ? row.prix.prixUnitaire : 0),
                sortable: true,
                center: true,
                format: (row, index) => {
                    return <div className='font-danger' style={{ fontWeight: 'bold' }}>{numberWithCommas(parseInt(row.prix.prixUnitaire))}</div>
                }

            },

            {
                name: "Action",
                selector: 'action',
                sortable: true,
                center: true,
                format: (row, index) => {
                    return <div onClick={() => this.handleModifierProduit(row)}>
                        <span style={{ cursor: 'pointer' }}><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i></span>
                    </div>
                },

            },
        ];


        let categoriesData = categories && categories.length ?
            categories.map((categorie, k) => {
                return {
                    value: categorie.codCat,
                    label: categorie.libCat

                }
            }) : [];


        return (
            <Fragment>
                <Breadcrumb parent="Article" title="Liste des Articles" />

                <Container fluid={true}>

                    <Row>
                        <Col md="12" className="project-list">
                            <Card>
                                <Row>
                                    <Col sm="5">
                                        <FormGroup>

                                            <Select
                                                value={selectedOption}
                                                onChange={this.handleChangeSelectCategorie}
                                                options={categoriesData}
                                                //name="codClient"
                                                //label="codClient"
                                                placeholder="Filtrer par Catégorie..."

                                            />
                                        </FormGroup>

                                    </Col>
                                    <Col sm="7">
                                        <div className="text-right">
                                            <h5>Nombre Articles: {totalArticles}</h5>
                                        </div>
                                        {/*<div className="text-right">
                                            <FormGroup className="mb-0 mr-0"></FormGroup>
                                            <Link className="btn btn-primary" style={{ color: 'white' }} to={`${process.env.PUBLIC_URL}/ajouterCommande/EvolutioSystem`}> <PlusCircle />{"Ajouter Commande"}</Link>
        </div>*/}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    {loading === true ?
                        <Row>
                            <CardBody className="row">


                                <Col md="12">
                                    {emptyData === false ?
                                        <div>
                                            <h6 className="sub-title mb-0 text-center" style={{ color: '#ae33f9' }}>{"Compilation des données en cours..."}</h6>
                                            <div className="loader-box">
                                                <div className="loader-22"></div>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <h6 className="sub-title mb-0 text-center text-danger">{"Aucune donnée disponible"}</h6>
                                            <div className="loader-box">
                                                <div className="loader-35"></div>
                                            </div>
                                        </div>
                                    }

                                </Col>

                            </CardBody>
                        </Row>
                        :
                        <Row>


                            <Col sm="12">
                                <Card>
                                    <CardBody>

                                        <div className="table-responsive product-table">
                                            <Media query="(max-width: 699px)" render={() =>
                                            (
                                                <DataTable
                                                    noHeader
                                                    columns={productColumnsPhone}
                                                    data={articles}

                                                    //selectableRows
                                                    //expandableRowsComponent={ExpandedComponent}
                                                    expandOnRowClicked
                                                    //expandableRowsHideExpander
                                                    fixedHeader
                                                    fixedHeaderScrollHeight="600px"
                                                    pointerOnHover
                                                    responsive
                                                    subHeaderAlign="right"
                                                    subHeaderWrap
                                                    //dense
                                                    pagination
                                                    //paginationComponent={BootyPagination}

                                                    //customStyles={customStyles}
                                                    highlightOnHover
                                                //pointerOnHover
                                                />

                                            )}
                                            />
                                            <Media query="(min-width: 700px)" render={() =>
                                            (
                                                <DataTable
                                                    noHeader
                                                    columns={productColumns}
                                                    data={articles}

                                                    //selectableRows
                                                    //expandableRowsComponent={ExpandedComponent}
                                                    expandOnRowClicked
                                                    //expandableRowsHideExpander
                                                    fixedHeader
                                                    fixedHeaderScrollHeight="600px"
                                                    pointerOnHover
                                                    responsive
                                                    subHeaderAlign="right"
                                                    subHeaderWrap
                                                    //dense
                                                    pagination
                                                    //paginationComponent={BootyPagination}

                                                    //customStyles={customStyles}
                                                    highlightOnHover
                                                //pointerOnHover
                                                />

                                            )}
                                            />

                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }



                    <Modal className="modal-dialog modal-lg" centered={true} scrollable={true} isOpen={open}>

                        <ModalHeader toggle={this.onCloseModalDetailCommande}>

                            <Row>
                                <Col md={12}>
                                    <h5 style={{ color: "#1D75BB" }}>Modification Produit</h5>


                                </Col>
                            </Row>
                        </ModalHeader>
                        <ModalBody /*style={{ maxHeight: '800px', maxWidth:'800px', overflow: 'auto' }}*/ toggle={this.onCloseModalDetailCommande}>

                            <form id="formElem">
                                <div className="product-box row">

                                    <Col lg="6" className="product-img">
                                        {/*
                                    <input type="file" name="picture" onChange={(e) => this.handleChangeFile(e.target.files)} accept="image/*" />

                                        <ImageUploader
                                            withIcon={false}
                                            withPreview={true}
                                            label=""
                                            singleImage={true}
                                            buttonText="Upload Images"
                                            //buttonClassName
                                            //withLabel= {true}
                                            //label
                                            //fileSizeError
                                            onChange={this.onDrop}
                                            imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg"]}
                                            maxFileSize={1048576}
                                            fileSizeError=" file size is too big"

                                        />
                                    */}
                                        {produitSelectionne.imgLink === "" ?
                                            ( img_server === "SAILS" ?

                                            <Dropzone
                                                getUploadParams={this.getUploadParams}
                                                onChangeStatus={this.handleChangeStatus}
                                                maxFiles={1}
                                                multiple={false}
                                                canCancel={false}
                                                inputContent="Choisir une Image(Taille recommandée 1000x600 px)"
                                                styles={{
                                                    dropzone: { height: 200 },
                                                    dropzoneActive: { borderColor: 'green' },
                                                }}
                                            />
                                            :

                                            <Dragger {...props}   /*customRequest={this.updateProductImgExpressJs}*/>
                                                <div style={{ width: '100%' }}>
                                                    <p className="ant-upload-drag-icon">
                                                        <Icon type="inbox" />
                                                    </p>
                                                    <p className="ant-upload-text">Choisir une Image(Taille recommandée 1000x600 px)</p>
                                                </div>
                                            </Dragger>

                                            )
                                            
                                            :
                                            <img className="img-fluid" src={`${img_base_url}/images/produits/${produitSelectionne.imgLink}`} style={{ maxWidth: "350px", maxHeight: "350px" }} alt="" />
                                        }

                                        {/* <img className="img-fluid" src={require("../../assets/images/menu/djewp.jpg" /*+ item.img/)} alt="Produit" />*/}

                                    </Col>
                                    <Col lg="6" className="product-details  text-left">
                                        <h4>{produitSelectionne.designation}</h4>
                                        <div className="product-price">

                                            <div>
                                                {numberWithCommas(parseInt(produitSelectionne.prix && produitSelectionne.prix.prixUnitaire))}{" F Cfa"}
                                            </div>

                                        </div>
                                        <div className="product-view">
                                            <h6 className="f-w-600">{"Catégorie"}</h6>
                                            <p className="mb-0">{produitSelectionne && (produitSelectionne.categorie.libCat || produitSelectionne.codeCat)}</p>
                                        </div>
                                        <div>
                                            {produitSelectionne.imgLink !== "" ?
                                                (img_server === "SAILS" ? 
                                                
                                                <ImageUploader
                                                     withIcon={false}
                                                     withPreview={true}
                                                     label=""
                                                     singleImage={true}
                                                     buttonText="Modifier l'Image"
                                                     //buttonClassName
                                                     //withLabel= {true}
                                                     //label
                                                     //fileSizeError
                                                     onChange={this.onDrop}
                                                     imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg"]}
                                                     maxFileSize={1048576}
                                                     fileSizeError="La taille du fichier est trop grande"
 
                                                 />
                                                 :

                                                 <Dragger {...props}   /*customRequest={this.updateProductImgExpressJs}*/>
                                                    <div style={{ width: '100%' }}>
                                                        <p className="ant-upload-drag-icon">
                                                            <Icon type="inbox" />
                                                        </p>
                                                        <p className="ant-upload-text">Modifier l'Image</p>
                                                    </div>
                                                </Dragger>

                                                )
                                                
                                                /* MISE A JOUR IMAGE POUR STOCKAGE SAILS JS
                                                <ImageUploader
                                                     withIcon={false}
                                                     withPreview={true}
                                                     label=""
                                                     singleImage={true}
                                                     buttonText="Modifier l'Image"
                                                     //buttonClassName
                                                     //withLabel= {true}
                                                     //label
                                                     //fileSizeError
                                                     onChange={this.onDrop}
                                                     imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg"]}
                                                     maxFileSize={1048576}
                                                     fileSizeError="La taille du fichier est trop grande"
 
                                                 />*/
                                                :
                                                ""
                                            }
                                        </div>

                                    </Col>
                                </div>
                            </form>

                        </ModalBody>
                        <ModalFooter>
                            <div className="addcart-btn">

                                {/*<Button color="primary" className="mr-2 mt-2" onClick={this.addCart} disabled={loading ? loading : loading} >{loading ? "Exécution en cours..." : "Valider"}</Button>*/}
                                <Button onClick={this.onCloseModalDetailCommande} color="secondary" className="mr-2 mt-2">{"Fermer"}</Button>
                            </div>
                        </ModalFooter>
                    </Modal>
                </Container>
            </Fragment>
        );
    }
}


export default translate(ListeArticles);
